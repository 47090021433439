export const orderStatusConfig = [
  { label: "Placed", value: "Placed" },
  { label: "Out for delivery", value: "Out for delivery" },
  { label: "Delivered", value: "Delivered" },
  { label: "Cancelled", value: "Cancelled By Shipper" },
  { label: "Cancelled By Buyer", value: "Cancelled By Buyer" },
  { label: "Return Requested", value: "Return Requested" },
  { label: "Refunded", value: "Refunded" },
];

export const userTypeConfig = [
  { label: "Fan", value: "Fan" },
  { label: "Influencer", value: "Influencer" },
  { label: "Assistant", value: "Assistant" },
  { label: "Agency", value: "AgencyUser" },
];

export const subscriberCounts = [
  { label: "0", value: { min: 0, max: 0 } },
  { label: "1-5", value: { min: 1, max: 5 } },
  { label: "6-10", value: { min: 6, max: 10 } },
  { label: "11-50", value: { min: 11, max: 50 } },
  { label: "51-100", value: { min: 51, max: 100 } },
  { label: "101-500", value: { min: 101, max: 500 } },
  { label: "500+", value: { min: 500 } },
];

export const invitationsStatusColor = {
  pending: "warning",
  accepted: "success",
  expired: "danger",
};

export const contentModerationStatusList = [
  "User Blocked",
  "Content within guidelines",
  "Content Removed",
  "Pending Inquiry",
];

export const contentModerationReasonList = [
  "Explicit Content",
  "Hate Speech",
  "Promotes Illegal Activity",
  "Racism",
  "Violence",
  "Other",
];

export const subscriptionCounts = [
  { label: "0", value: { min: 0, max: 0 } },
  { label: "1", value: { min: 1, max: 1 } },
  { label: "2", value: { min: 2, max: 2 } },
  { label: "3-5", value: { min: 3, max: 5 } },
  { label: "6-10", value: { min: 6, max: 10 } },
  { label: "10+", value: { min: 10 } },
];

export const spentCounts = [
  { label: "0", value: { min: 0, max: 0 } },
  { label: "1-50", value: { min: 1, max: 50 } },
  { label: "51-200", value: { min: 51, max: 200 } },
  { label: "201-500", value: { min: 201, max: 500 } },
  { label: "500+", value: { min: 500 } },
];

export const attendingCounts = [
  { label: "0", value: { min: 0, max: 0 } },
  { label: "1-50", value: { min: 1, max: 50 } },
  { label: "51-200", value: { min: 51, max: 200 } },
  { label: "201-500", value: { min: 201, max: 500 } },
  { label: "500+", value: { min: 500 } },
];

export const priceCounts = [
  { label: "0-20", value: { min: 0, max: 20 } },
  { label: "20-50", value: { min: 20, max: 50 } },
  { label: "50-100", value: { min: 50, max: 100 } },
  { label: "100-250", value: { min: 100, max: 250 } },
  { label: "250-500", value: { min: 250, max: 500 } },
  { label: "500+", value: { min: 500 } },
];

export const salesCounts = [
  { label: "0", value: { min: 0, max: 0 } },
  { label: "1-5", value: { min: 1, max: 50 } },
  { label: "6-10", value: { min: 6, max: 10 } },
  { label: "11-20", value: { min: 11, max: 20 } },
  { label: "21-50", value: { min: 21, max: 50 } },
  { label: "51-100", value: { min: 51, max: 100 } },
  { label: "100+", value: { min: 100 } },
];

export const paymentTypes = [
  "tips",
  "subscription",
  // "post",
  "message",
  "withdrawal",
  "vault",
  "unlock",
  "payperview",
  "chargeback",
  "refund",
  "liveEvent",
  // "remove post"
];

export const liveStatusList = ["Scheduled", "Canceled", "Ongoing", "Completed"];

export const paymentStatusList = [
  "pending",
  "released",
  "approved",
  "flagged",
  "refunded",
];

export const withdrawalStatus = [
  "accepted",
  "requested",
  "rejected",
  "pending",
  "paid",
  "failed",
  "canceled",
  "updated",
];

export const monthList = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const regexConfig = {
  digitOnly: /^[0-9]*$/,
  phone: /^(\+\d{1,3}[- ]?)?\d{8,11}$/,
  email:
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  url: /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
  username: /^[a-z0-9_-]{3,16}$/,
};

export const rsvp = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const socialIconConfig = {
  facebook: "fa fa-facebook",
  twitter: "fa fa-twitter",
  youtube: "fa fa-youtube-play",
  instagram: "fa fa-instagram",
  tiktok: "fa fa-tiktok",
};

/***********************  Dynamic Configuration  ************************/

// app version
export const APP_VERSION = process.env.REACT_APP_APP_VERSION;

// frontend deploy fan base url
export const FRONTEND_FAN_BASE_URL =
  process.env.REACT_APP_FRONTEND_FAN_BASE_URL;

// frontend deploy creator base url
export const FRONTEND_CREATOR_BASE_URL =
  process.env.REACT_APP_FRONTEND_CREATOR_BASE_URL;

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const AFFILIATE_BASE_URL = process.env.REACT_APP_AFFILIATE_BASE_URL;

export const AWS_PUBLIC_IMAGE_BUCKET_NAME =
  process.env.REACT_APP_AWS_PUBLIC_IMAGE_BUCKET_NAME;

/*********************  General values ***********************/
export const DEFAULT_PROFILE_PICTURE =
  process.env.REACT_APP_DEFAULT_PROFILE_PICTURE;

export const DEFAULT_COVER_PICTURE =
  process.env.REACT_APP_DEFAULT_COVER_PICTURE;

export const APP_LOGO = process.env.REACT_APP_LOGO;

export const APP_NAME = process.env.REACT_APP_NAME;

export const APP_THEME_HEX_COLOR = process.env.REACT_APP_THEME_HEX_COLOR;

export const TOKEN_NAME = `${process.env.REACT_APP_NAME}_Admin_Token`;

export const CURRENCY_SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;

export const NO_IMAGE_AVAILABLE_URL =
  process.env.REACT_APP_NO_IMAGE_AVAILABLE_URL;

export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const GOOGLE_SCOPES = process.env.REACT_APP_GOOGLE_SCOPES;

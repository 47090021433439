import cloneDeep from "clone-deep";
import toast from "react-hot-toast";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { history } from "../App";
import { getToken } from "../http/token-interceptor";
import S3BucketUploader from "../s3-bucket-uploader";
import { PostManager } from "../post-manager";
import { UploadQueueManager } from "../upload-queue-manager";
import { AWS_PUBLIC_IMAGE_BUCKET_NAME, BASE_URL, TOKEN_NAME } from "../config";
import ReactHtmlParser from "react-html-parser";

export const logout = async (navRef, isDeviceLogoutRequired) => {
  localStorage.removeItem(TOKEN_NAME);

  if (navRef) {
    navRef.replace("/login");
  } else {
    history.replace("/login");
  }
};

export const decodeToken = (token) => {
  return jwt_decode(token);
};

export const deepClone = (data) => {
  return cloneDeep(data);
};

export const showToast = (message, type = "error", duration = 4000) => {
  toast[type](message, { duration });
};

export const sleepTime = (n) => new Promise((r) => setTimeout(() => r(), n));

export const getPostedDateValue = (postedDate) => {
  if (moment().isSame(postedDate, "year")) {
    return moment(postedDate).format("MMM DD");
  } else {
    return moment(postedDate).format("MMM DD, YYYY");
  }
};

export const sortedThreads = (arr, attr) => {
  return arr.sort((t1, t2) => {
    return new Date(t2[attr]) - new Date(t1[attr]);
  });
};

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  showToast("Copied", "success");
};

export const formatCurrencyValue = (data) => {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  let currency = formatter.format(data);
  if (currency && currency.split(".")[1] === "00") {
    return currency.split(".")[0];
  }
  return currency; /* $2,500.00 */
};

export const formatDecimalNumber = (number, decimal = 2) => {
  if (number) {
    number = number.toFixed(decimal);
    if (number && number.split(".")[1] === "00") {
      return number.split(".")[0]; /* 50 */
    }
    return number; /* 50.50 */
  }
  return 0;
};

export const capitalize = (s) => {
  if (!s || typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const formatDate = (date) => {
  if (!date) return "";

  if (moment().isSame(date, "year")) {
    return moment(date).format("MMM DD");
  } else {
    return moment(date).format("MMM DD, YYYY");
  }
};

export const formatDateAndTime = (date) => {
  if (!date) return "";

  if (moment().isSame(date, "year")) {
    return moment(date).format("MMM DD - hh:mm a");
  } else {
    return moment(date).format("MMM DD, YYYY - hh:mm a");
  }
};

export const getPhoneNumberFromBrackets = (number) => {
  let phone = "";
  if (number) {
    if (number.includes(")")) {
      phone = number.split(")")[1];
    } else {
      phone = number;
    }
  }
  return phone;
};

export const formatPhoneNumber = (phone) => {
  if (phone) {
    if (phone.includes(")")) {
      let phoneSplit = phone.split(")");
      return `${phoneSplit[0].slice(1)} ${phoneSplit[1]}`;
    } else {
      return phone;
    }
  } else {
    return "N/A";
  }
};

export const extractQueryParams = () => {
  let {
    location: { search: queryParamString },
  } = window;
  let params = {};
  if (queryParamString.length > 1 && queryParamString.indexOf("?") > -1) {
    queryParamString = queryParamString.replace("?", "");
    queryParamString = decodeURIComponent(queryParamString);
    if (queryParamString.indexOf("&") === -1) {
      // Contains only one param
      const paramParts = queryParamString.split("=");
      params[paramParts[0]] = paramParts[1];
    } else {
      // Contains multiple params
      const queryParams = queryParamString.split("&");
      queryParams.forEach((queryParam) => {
        const paramParts = queryParam.split("=");
        params[paramParts[0]] = paramParts[1];
      });
    }
  }
  return params;
};

export const onUploadProgress = (evt, onProgressCallback) => {
  let uploadPercentage = parseInt((evt.loaded * 100) / evt.total) + "%";

  onProgressCallback(uploadPercentage);
};

export const onComplete = (error, success) => {
  console.log("error, success :", error, success);
};

// program to get the file extension
export const getFileExtension = (filename) => {
  // get file extension
  const extension = filename.split(".").pop();
  return extension;
};

export const uploadPhotoToCloudinary = (
  photo,
  type = "blob",
  fileName = null,
  fileType = "image",
  isLargeFile = false,
  onProgressCallback = () => {},
  isFakeCall = false
) => {
  return new Promise(async (resolve, reject) => {
    if (isFakeCall) {
      resolve("");
    } else {
      if (type === "blob") {
        fileName = Math.random().toString(36).substring(2);

        if (photo.name) {
          fileName = `${fileName}.${getFileExtension(photo.name)}`;
        } else {
          fileName = fileName + (fileType === "image" ? ".jpg" : ".pdf");
        }

        if (fileType === "image" || fileType === "pdf") {
          try {
            const token = await getToken();

            const config = await S3BucketUploader.getCreds(
              BASE_URL + "/awstempcreds",
              token
            );
            //  Initialize S3 Uploader
            const s3Uploader = new S3BucketUploader(config);
            const s3Response = await s3Uploader.uploadFile(
              photo,
              onComplete,
              (e) => onUploadProgress(e, onProgressCallback),
              fileType
            );
            resolve(s3Response.Location);
          } catch (error) {
            reject(error);
          }
        }
      }
    }
  });
};

export const getAWSBucketName = (type) => {
  switch (type) {
    case "image":
      return AWS_PUBLIC_IMAGE_BUCKET_NAME;
    default:
      return null;
  }
};

/**
 * uploadFiles is Object Array;
 * object key is;
 * - uploadData
 * - previewBlob
 * - type
 *
 * @param {Array} uploadFiles - file Object Array
 * @returns Array Object; object key is;
 * - title
 * - url
 * - docType
 */
export const uploadFileOnServer = (uploadFiles) => {
  return new Promise((resolve, reject) => {
    const uploadedFiles = [];

    console.log("uploadFiles", uploadFiles);

    if (uploadFiles && uploadFiles.length) {
      let postID = PostManager.addMediaFilesCount(uploadFiles.length);

      PostManager.onAllMediaFilesUploadCompleted(postID, async (id) => {
        if (id.postID === postID) {
          console.log("uploadedFiles :>> ", uploadedFiles);
          await sleepTime(500);
          resolve(uploadedFiles);
          PostManager.deletePostID(postID);
        } else {
          return;
        }
      });

      uploadFiles.forEach((uploadFile, index) => {
        let mediaData = {
          file: uploadFile.uploadData,
          blobObject: uploadFile.previewBlob,
        };
        const uploadId = UploadQueueManager.addMediaToQueue(
          mediaData,
          uploadFile.type
        );

        // Listen for upload complete
        UploadQueueManager.onUploadComplete(uploadId, async (mediaResponse) => {
          PostManager.onSingleMediaFileUploadCompleted(postID);
          console.log("mediaResponse", mediaResponse, mediaResponse.fileUrl);
          // Upload complete
          // Get content id from backend
          uploadedFiles.push({
            title: uploadFile.uploadData.name,
            url: mediaResponse.fileUrl,
            docType:
              uploadFile.type === "pdf"
                ? getFileExtension(uploadFile.uploadData.name)
                : uploadFile.type,
          });
        });
      });
    }
  });
};

/**
 * this function accept time in T format "2020-12-03T13:41:17.672Z"
 * Will tell if the time is beyond 24 hours
 * and Will return boolean value
 *
 * @param {Date} time - 2020-12-03T13:41:17.672Z
 *
 * @return {Boolean} true // false
 */
export const isTimeMoreThan24h = (time) => {
  let isGreater = false;
  let hours = Math.abs(new Date() - new Date(time)) / 36e5;
  // The subtraction returns the difference between the two dates in milliseconds.
  // 36e5 is the scientific notation for 60*60*1000,
  // dividing by which converts the milliseconds difference into hours.
  if (hours > 24) {
    isGreater = true;
  }

  return isGreater;
};

export const parseForTextField = (text) => {
  if (text.length) {
    return text.replace(/\r?<br\/>/g, "\n");
  }
  return "";
};

export const reactHtmlParser = (text = "") => {
  let parseText = text ? ReactHtmlParser(text) : "";
  if (parseText) {
    parseText = parseText.map((each) => {
      if (typeof each === "string") {
        return each;
      }
      return "\n";
    });
    parseText = parseText.join(" ");
  }
  // return string text
  return parseText;
};

export const errorHandler = (error) => {
  console.log("error>>", error);
  showToast(
    error?.reason?.length
      ? error?.reason
      : "Something went wrong, Try again after some time."
  );
};

export const getOnlyUniqueArray = (value, index, self) => {
  return value && self.indexOf(value) === index;
};

export const formatDurationInSecondToHour = (ss) => {
  if (!ss) return "";

  if (ss > 59) {
    let mm = Math.floor(ss / 60);
    if (mm > 59) {
      let hh = Math.floor(mm / 60);
      mm = mm % 60;
      return `${hh < 9 ? "0" + hh : hh} hr ${mm < 9 ? "0" + mm : mm} min`;
    } else {
      ss = ss % 60;
      return `${mm < 9 ? "0" + mm : mm} min ${ss < 9 ? "0" + ss : ss} sec`;
    }
  } else {
    return `${ss < 9 ? "0" + ss : ss} sec`;
  }
};

export const formatDateForTopics = (dateString) => {
  const date = moment(dateString);
  const formattedDate = date.format("MMM Do, YYYY");
  return formattedDate;
};

export const openUrlOnNewTab = (url) => {
  window.open(url, "_blank");
};

export const createYoutubeLinkFromId = (id) => {
  return `https://www.youtube.com/embed/${id}`;
};

export const handleNameObj = (nameObj) => {
  if (nameObj?.full) return nameObj?.full?.trim();

  let name = "N/A";
  if (nameObj?.first) {
    name = "";
    name = nameObj?.first;
    if (nameObj?.last) {
      name = name + " " + nameObj.last;
    }
  }
  return name?.trim();
};

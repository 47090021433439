import React from "react";
import Loader from "react-loader-spinner";
import colors from "../assets/styles/scss/style.scss";

const StaticFullPageLoader = ({ message }) => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        zIndex: 9999999,
        opacity: 0.95,
        backgroundColor: "white",
      }}
    >
      <div className="loaderWrap">
        <Loader type="Oval" color={colors?.themeColor} />
        <p>{message || "Loading..."}</p>
      </div>
    </div>
  );
};

export default StaticFullPageLoader;

import { TOKEN_NAME } from "../config";
import { decodeToken, logout } from "../helper-methods/index";

export const isUserAuthenticated = () => {
  let isUserAuth = false;

  if (localStorage[TOKEN_NAME]) {
    const tokenData = decodeToken(localStorage[TOKEN_NAME]);

    const expDate = new Date(tokenData.exp * 1000); // expire in sec. convert in msec.

    if (expDate <= new Date()) {
      console.log("expire");
      logout();
    } else {
      isUserAuth = true;
    }
  }

  return isUserAuth;
};

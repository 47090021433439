import { Route, Redirect } from "react-router-dom";
import React from 'react';

import { isUserAuthenticated } from "../guards/auth-guard";

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isUserAuthenticated() === true ? (
            <Redirect
            to={{ pathname: rest.redirectRoute, extras: { ...rest.location } }}
            />
          ) : (
            <Component {...props} />
        )
      }
    />
  );
}
 
export default PublicRoute;
import React, { Component } from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Toaster } from "react-hot-toast";
import PublicRoute from "./components/public-route";
import FullPageLoader from "./components/StaticFullPageLoader";
import Loadable from "react-loadable";
import { QueryClient, QueryClientProvider } from "react-query";

export const history = createBrowserHistory();

const DefaultLayout = Loadable({
  loader: () => import("./containers/DefaultLayout/DefaultLayout"),
  loading() {
    return <FullPageLoader />;
  },
});

const ForgotPassword = Loadable({
  loader: () => import("./pages/forgot-password"),
  loading() {
    return <FullPageLoader />;
  },
});

const Login = Loadable({
  loader: () => import("./pages/login"),
  loading() {
    return <FullPageLoader />;
  },
});

const queryClient = new QueryClient();

class App extends Component {
  render() {
    if (process.env.REACT_APP_BACKEND_ENV === "prod") console.log = () => {};

    return (
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Toaster position="bottom-right" reverseOrder={false} />

          <div className="App">
            {/* <Navbar /> */}
            <div>
              <Switch>
                <PublicRoute
                  path="/login"
                  component={Login}
                  redirectRoute={"/home"}
                />
                <PublicRoute
                  path="/forgot-password"
                  component={ForgotPassword}
                  redirectRoute={"/home"}
                />

                <Route path="/" component={DefaultLayout} />

                <Route path="*" render={() => <Redirect to="/" />} />
              </Switch>
            </div>
          </div>
        </BrowserRouter>
      </QueryClientProvider>
    );
  }
}

export default App;

import { TOKEN_NAME } from "../config";

export const getToken = () => {
  return new Promise((resolve, reject) => {
    let token = null;
    // const oldState = store.getState();
    // const state = { ...oldState };
    // Try to get token from state
    if (localStorage[TOKEN_NAME]) {
      token = localStorage[TOKEN_NAME];
    }
    resolve(token);
  });
};
